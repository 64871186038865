import React, { ReactElement } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '../components/layout';
import SEO from '../components/seo';

// FIXME: Add typing for Gatsby GrapthQL queries
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Cookies: React.FC<any> = (): ReactElement => (
  <StaticQuery
    query={indexQuery}
    render={data => {
      const { t } = useTranslation();
      const siteTitle = data.site.siteMetadata.title;
      const categoriesCopyright = data.allStrapiCategories.nodes;
      const recipesCopyright = data.allStrapiRecipes.nodes;
      return (
        <Layout title={siteTitle}>
          <SEO title={t('CREDITS_TITLE_SEO')} keywords={[`creditos`, `imagenes`, 'atribuciones', 'licencias']} />
          <div className="section-page-tags section-page-general wrap">
            <h2>{t('CREDITS_TITLE')}</h2>
            {t('CREDITS_DESCRIPTION')}
            <hr></hr>
            <h3>{t('CREDITS_CATEGORIES_TITLE')}</h3>
            <ul>
              <li>
                <a href="https://www.freepik.es/fotos/fondo">
                  Foto de Fondo creado por valeria_aksakova - www.freepik.es
                </a>
              </li>
              {categoriesCopyright.map(category => {
                return (
                  category.copyright && (
                    <li key={category.id} dangerouslySetInnerHTML={{ __html: category.copyright }}></li>
                  )
                );
              })}
            </ul>
            <h3>{t('CREDITS_RECIPES_TITLE')}</h3>
            <ul>
              <li>
                <a href="https://developers.facebook.com/docs/instagram/oembed/">oEmbed de Instagram.</a> Las imágenes y
                vídeos empleados en SiChef.app proceden del uso autorizado por parte de Instagram a partir de su
                servicio web expuesto &apos;oEmbed de Instagram&apos;, cumpliendo todas sus políticas de uso y de
                privacidad. El caso de uso presente el Sichef.app cumple con el segundo caso de uso presente en la
                política de Oembed de Instagram &apos;Inserción de publicaciones en sitios web y blogs.&apos;
              </li>
              {recipesCopyright.map(recipe => {
                return (
                  recipe.copyright && <li key={recipe.id} dangerouslySetInnerHTML={{ __html: recipe.copyright }}></li>
                );
              })}
            </ul>
          </div>
        </Layout>
      );
    }}
  />
);

const indexQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allStrapiCategories(sort: { fields: recipes, order: DESC }) {
      nodes {
        id
        copyright
      }
    }
    allStrapiRecipes(sort: { order: DESC, fields: source___date }) {
      nodes {
        id
        copyright
      }
    }
  }
`;

export default Cookies;
